body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.iframe-container {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100vw;
}
